import { useMemo } from 'react'
import { APIQueueTaskKind, APIQueueTaskStatus, type APIQueueTask, type PaginatedRequestQueryParams } from '@visorpro/client'
import {
    MaterialReactTable,
    type MRT_ColumnDef,
    type MRT_Updater,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
} from 'material-react-table';
import _ from 'lodash'
import { Close, Check, HistoryToggleOff, Remove } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { type DateTime } from 'luxon';
import { type APIUpdateDocumentRequest } from '../stores/documents';
import { useAdminMaterialReactTable, useMultiValueColumnDef } from './table/mui-table';
import { formatDate } from '../util/format';
import { type APIGetQueueTaskRequestFilteringParams } from '@visorpro/client/dist/PipelineClient/services';

export interface DocumentsTableProps {
    tasks: APIQueueTask[]
    isLoading?: boolean
    isSaving?: boolean
    rowCount?: number
    pagination?: PaginatedRequestQueryParams
    filters?: Omit<APIGetQueueTaskRequestFilteringParams, 'offset' | 'limit'>
    sortings?: MRT_SortingState
    setPagination?: (pagination: PaginatedRequestQueryParams) => void
    setFilters?: (filters: Omit<APIGetQueueTaskRequestFilteringParams, 'offset' | 'limit'>) => void
    setSortings?: (state: MRT_SortingState) => void
    updateDocumentEnabled?: (id: string, isEnabled: boolean) => void
    updateDocument?: (documentId: string, body: APIUpdateDocumentRequest) => void
    setDataSets?: (documentId: string, addedDatasetIds: string[], removedDataSetIds: string[]) => void
    setModels?: (documentId: string, addedModelIds: string[], removedModelIds: string[]) => void
}

export const QueueTasksTable = ({ tasks: data, rowCount, pagination, setPagination, filters, setFilters, sortings, setSortings, isLoading, isSaving }: DocumentsTableProps) => {
    const paginationState = useMemo(() => {
        if (pagination === undefined) return undefined

        return {
            pageIndex: (pagination.offset && pagination.limit) ? pagination.offset / pagination.limit : 0,
            pageSize: pagination.limit || 1000,
        }
    }, [pagination])

    const onPaginationChange = (pagination: MRT_Updater<MRT_PaginationState>) => {
        if (typeof pagination === 'function') {
            const newPagination = pagination(table.getState().pagination)
            setPagination?.({
                offset: newPagination.pageIndex * newPagination.pageSize,
                limit: newPagination.pageSize,
            })
        } else {
            setPagination?.({
                offset: pagination.pageIndex * pagination.pageSize,
                limit: pagination.pageSize,
            })
        }
    }

    const columnFiltersState = useMemo(() => {
        if (filters === undefined) return undefined

        const columnFilters: MRT_ColumnFiltersState = []
        Object.entries(filters).forEach(([key, value]) => {
            columnFilters.push({ id: key, value })
        })

        return columnFilters
    }, [filters])

    const onColumnFiltersChange = (filters: MRT_Updater<MRT_ColumnFiltersState>) => {
        const oldFilters = table.getState().columnFilters
        let newFilters: MRT_ColumnFiltersState

        if (typeof filters === 'function') {
            newFilters = filters(oldFilters)
        } else {
            newFilters = filters
        }

        if (_.isEqual(newFilters, oldFilters)) return

        const filtersBody: APIGetQueueTaskRequestFilteringParams = {}
        newFilters.forEach((filter) => {
            filtersBody[filter.id] = filter.value as string | number | string[] | number[] | undefined;
        })

        setFilters?.(filtersBody)
        table.firstPage()
    }

    const useStatusOptions = (statusOptions: string[]) => {
        return statusOptions.map((kind) => ({ label: _.startCase(kind), value: kind }))
    }
    const statusOptions = Object.values(APIQueueTaskStatus);
    const { Filter: StatusFilter } = useMultiValueColumnDef<APIQueueTask, APIQueueTaskStatus>({
        fieldName: 'Status',
        optionsData: statusOptions,
        useOptions: useStatusOptions,
    })

    const useKindOptions = (kindOptions: string[]) => {
        return kindOptions.map((kind) => ({ label: _.startCase(kind), value: kind }))
    }
    const kindOptions = Object.values(APIQueueTaskKind);
    const { Filter: KindFilter } = useMultiValueColumnDef<APIQueueTask, APIQueueTaskKind>({
        fieldName: 'Kind',
        optionsData: kindOptions,
        useOptions: useKindOptions,
    })

    const columns = useMemo<MRT_ColumnDef<APIQueueTask>[]>(() => [
        {
            accessorKey: 'status',
            header: 'Status',
            size: 60,
            minSize: 50,
            maxSize: 80,
            Cell: ({ cell }) => {
                const value = cell.getValue<string>();
                const icon = getQueueTaskStatusIcon(value as APIQueueTaskStatus)
                return icon
            },
            enableColumnFilter: setFilters !== undefined,
            Filter: StatusFilter,
            filterFn: 'equals',
            columnDefType: 'display',
        },
        {
            accessorKey: 'kind',
            header: 'Kind',
            size: 100,
            Cell: ({ cell }) => {
                const value = cell.getValue<string>();
                return _.startCase(value);
            },
            enableColumnFilter: setFilters !== undefined,
            Filter: KindFilter,
            filterFn: 'equals',
        },
        {
            id: 'metadata',
            header: 'Metadata',
            columns: [
                {
                    id: 'document_id',
                    accessorFn: (originalRow) => originalRow.metadata?.document_id as string,
                    header: 'Document',
                    enableColumnFilter: false,
                    filterFn: 'equals',
                },
                {
                    id: 'sha512_hash',
                    accessorFn: (originalRow) => originalRow.metadata?.sha512_hash as string,
                    header: 'Hash',
                },
            ]
        },
        {
            id: 'time',
            header: 'Time',
            columns: [
                {
                    id: 'elapsed',
                    header: 'Elapsed',
                    columnDefType: 'display',
                    size: 100,
                    accessorFn: (originalRow) => {
                        const startedAt = originalRow.started_at
                        const endedAt = originalRow.ended_at
                        if (!startedAt || !endedAt) return undefined
                        const duration = endedAt.diff(startedAt, ['hours', 'minutes', 'seconds']).toObject()
                        const hours = Math.floor(duration.hours ?? 0).toString().padStart(2, '0')
                        const minutes = Math.floor(duration.minutes ?? 0).toString().padStart(2, '0')
                        const seconds = Math.floor(duration.seconds ?? 0).toString().padStart(2, '0')
                        return `${hours}:${minutes}:${seconds}`
                    },
                    Cell: ({ cell }) => cell.getValue<string>(),
                },
                {
                    accessorKey: 'started_at',
                    header: 'Started At',
                    Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
                },
                {
                    accessorKey: 'ended_at',
                    header: 'Ended At',
                    Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
                },
                {
                    accessorKey: 'created_at',
                    header: 'Created At',
                    Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
                },
                {
                    accessorKey: 'updated_at',
                    header: 'Updated At',
                    Cell: ({ cell }) => formatDate(cell.getValue<DateTime>()),
                },
            ]
        },
    ], [KindFilter, StatusFilter, setFilters]);

    const table = useAdminMaterialReactTable({
        columns,
        data,
        rowCount,
        initialState: {
            columnVisibility: {
                sha512_hash: false,
                updated_at: false,
            },
            columnPinning: {
                left: ['status', 'kind'],
            }
        },
        defaultColumn: {
            minSize: 80,
            size: 200,
            enableSorting: true,
        },
        enableSorting: setSortings !== undefined,
        enableRowSelection: false,
        getRowId: (doc) => doc.id,
        ...(setPagination && { manualPagination: true, onPaginationChange }),
        ...(setFilters && { manualFiltering: true, onColumnFiltersChange }),
        ...(setSortings && { manualSorting: true, onSortChange: setSortings }),
        state: {
            isLoading,
            showProgressBars: isSaving,
            ...(pagination && paginationState && { pagination: paginationState }),
            ...(filters && columnFiltersState && { columnFilters: columnFiltersState }),
            ...(sortings && { sorting: sortings }),
        },
    }, 'queue-tasks-table');

    return <MaterialReactTable table={table} />
}

export const getQueueTaskStatusIcon = (status: APIQueueTaskStatus) => {
    if (status === APIQueueTaskStatus.PENDING) {
        return <HistoryToggleOff color="primary" fontSize='small' />
    } else if (status === APIQueueTaskStatus.RUNNING) {
        return <CircularProgress
            variant="indeterminate"
            size={18}
            thickness={5}
            color="primary"
        />
    } else if (status === APIQueueTaskStatus.SUCCESSFUL) {
        return <Check color="success" fontSize='small' />
    } else if (status === APIQueueTaskStatus.FAILED) {
        return <Close color="error" fontSize='small' />
    } else if (status === APIQueueTaskStatus.CANCELLED) {
        return <Close fontSize='small' style={{ color: "grey" }} />
    }
    return <Remove fontSize='small' style={{ color: "grey" }} />
}